import { ModelDataSelectionForm } from "@/components/admin-components";
import {
    useGotContentBlock,
    useModalQueryParams,
    useVariableValueStore,
} from "@/hooks";
import { useUpdateActiveContentBlock } from "@/hooks/activeContentBlock";
import { useModelVariableStore, useTimeHorizonStore } from "@/hooks/store";
import {
    ContentBlockShape,
    ContentBlockType,
    ModelVariableDataType,
} from "@/models";
import { ModalContainer } from "@/modules/shared";
import React, { useEffect, useMemo, useState } from "react";
import { useGetContentBlockById } from "../modals/shared-queries/useGetContentBlockById";
import { queryClient } from "../QueryClient";
import { saveContentBlockModelDataSources } from "./saveContentBlockModelDataSources";
import { useMutation } from "@tanstack/react-query";
import { useContentBlockState } from "@/Pages/DesignStudio/Design/useContentBlockState";

function getTimeHorizonIds(contentBlock: ContentBlockShape) {
    return (
        contentBlock?.contentBlockModelDataSources
            ?.map((modelDataSource) =>
                modelDataSource.timeHorizons.map(
                    (timeHorizon) => timeHorizon.id,
                ),
            )
            .flat() || []
    );
}

function ModelDataSourceModalContents({
    modelDataContentBlock,
}: {
    modelDataContentBlock: ContentBlockShape;
}) {
    const { gotVariableValues } = useVariableValueStore();
    const { queryParams, closeModal } = useModalQueryParams(
        "ModelDataSourceModal",
    );

    const { timeHorizons } = useTimeHorizonStore();
    const { modelVariables } = useModelVariableStore();
    const { updateActiveContentBlock } = useUpdateActiveContentBlock();
    const { gotContentBlock } = useGotContentBlock();
    const { setContentBlock } = useContentBlockState();
    const [modelVariableIds, setModelVariableIds] = useState<string[]>(
        modelDataContentBlock?.contentBlockModelDataSources?.map(
            (modelDataSource) => modelDataSource.model_variable_id,
        ) || [],
    );
    const [timeHorizonIds, setTimeHorizonIds] = useState<string[]>(
        getTimeHorizonIds(modelDataContentBlock),
    );

    useEffect(() => {
        setModelVariableIds(
            modelDataContentBlock?.contentBlockModelDataSources?.map(
                (modelDataSource) => modelDataSource.model_variable_id,
            ) || [],
        );
        setTimeHorizonIds(getTimeHorizonIds(modelDataContentBlock));
    }, [modelDataContentBlock?.id, modelDataContentBlock?.updated_at]);

    const { mutate } = useMutation({
        mutationFn: saveContentBlockModelDataSources,
        onSuccess: ({ contentBlock, variableValues }) => {
            gotVariableValues(variableValues);
            updateActiveContentBlock(contentBlock);
            gotContentBlock(contentBlock);
            setContentBlock(contentBlock);
            queryClient.invalidateQueries({
                queryKey: ["contentBlockById"],
            });
            closeModal();
        },
        onError: (error) => {
            console.log(error);
        },
    });

    async function submit() {
        mutate({
            contentBlockId: queryParams.contentBlockId,
            modelVariableIds,
            timeHorizonIds,
        });
    }

    return (
        <form
            data-testid="model-data-source-modal"
            className="flex flex-col space-y-4 px-6 py-4 text-white"
            onSubmit={(e) => {
                e.preventDefault();
                submit();
            }}
        >
            <p className="text-xl">Select Model Variable</p>
            <div className="flex w-full flex-col border-b border-t border-gray-300">
                <ModelDataSelectionForm
                    modelVariables={Object.values(modelVariables).filter(
                        (modelVariable) =>
                            modelVariable.data_type ===
                            ModelVariableDataType.Number,
                    )}
                    timeHorizons={timeHorizons}
                    selectedModelVariables={Object.values(
                        modelVariables,
                    ).filter((modelVariable) =>
                        modelVariableIds.includes(modelVariable.id),
                    )}
                    setSelectedModelVariables={(modelVariables) => {
                        setModelVariableIds(() =>
                            modelVariables.map(
                                (modelVariable) => modelVariable.id,
                            ),
                        );
                    }}
                    selectedTimeHorizons={timeHorizons.filter((timeHorizon) =>
                        timeHorizonIds.includes(timeHorizon.id),
                    )}
                    setSelectedTimeHorizons={(timeHorizons) => {
                        setTimeHorizonIds(() =>
                            timeHorizons.map((timeHorizon) => timeHorizon.id),
                        );
                    }}
                    isSingleModelVariableSelection={true}
                    isSingleTimeHorizonSelection={
                        modelDataContentBlock.content_block_type ===
                        ContentBlockType["Model Value Display"]
                    }
                />
            </div>
            <div className="flex justify-end space-x-2">
                <button
                    data-testid="save-model-data-source"
                    className="2bluefocus:ring-2 inline-flex items-center rounded bg-blue-600 px-4 py-2 text-sm
                        font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none
                        focus:ring-blue-500 focus:ring-offset-2"
                >
                    Save
                </button>
                <button
                    onClick={(e) => {
                        e.preventDefault();
                        closeModal();
                        queryClient.invalidateQueries({
                            queryKey: ["contentBlockById"],
                        });
                    }}
                    className="inline-flex items-center rounded-md border border-transparent bg-red-600 px-4
                        py-2 text-sm font-medium text-white shadow-sm hover:bg-red-700
                        focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
                >
                    Cancel
                </button>
            </div>
        </form>
    );
}

export default function ModelDataSourceModal() {
    const { isAtModalUrl, queryParams } = useModalQueryParams(
        "ModelDataSourceModal",
    );

    const { data, isFetching } = useGetContentBlockById(
        queryParams.contentBlockId,
    );

    const contentBlock = useMemo(() => {
        return data;
    }, [data?.id, data?.updated_at]);

    return (
        <ModalContainer
            isModalOpen={isAtModalUrl}
            size="l"
            backgroundColor="#111928"
        >
            {!!isAtModalUrl && (
                <>
                    {!!(isFetching && !data) ? (
                        <div className="flex animate-pulse flex-col gap-4 p-6">
                            <div className="h-8 w-full rounded-md bg-gray-100"></div>
                            <div className="h-8 w-full rounded-md bg-gray-100"></div>
                            <div className="flex justify-end gap-2">
                                <div className="h-8 w-16 rounded-md bg-gray-100"></div>
                                <div className="h-8 w-16 rounded-md bg-gray-100"></div>
                            </div>
                        </div>
                    ) : (
                        <ModelDataSourceModalContents
                            modelDataContentBlock={contentBlock}
                        />
                    )}
                </>
            )}
        </ModalContainer>
    );
}
