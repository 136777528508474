import React, { ChangeEvent, useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import { TimeHorizon } from "@/models";
import { Switch } from "@headlessui/react";

type Props = {
    timeHorizons: TimeHorizon[];
    setTimeHorizons: (timeHorizonsIds: string[]) => void;
    selectedTimeHorizons: TimeHorizon[];
    isSingleSelect: boolean;
    isDisabled?: boolean;
};

type WrapperProps = {
    from: number;
    to: number;
    min: number;
    max: number;
    sliderColor: string;
    rangeColor: string;
};

const SliderWrapper = styled.div<WrapperProps>`
    input[type="range"]::-webkit-slider-thumb {
        -webkit-appearance: none;
        pointer-events: all;
        width: 14px;
        height: 14px;
        background-color: #fff;
        border-radius: 50%;
        box-shadow: 0 0 0 1px #c6c6c6;
        cursor: pointer;
    }

    input[type="range"]::-moz-range-thumb {
        -webkit-appearance: none;
        appearance: none;
        pointer-events: all;
        width: 14px;
        height: 14px;
        background-color: #fff;
        border-radius: 50%;
        box-shadow: 0 0 0 1px #c6c6c6;
        cursor: pointer;
    }

    input[type="range"]::-webkit-slider-thumb:hover {
        background: #f7f7f7;
    }

    input[type="range"]::-webkit-slider-thumb:active {
        box-shadow:
            inset 0 0 3px #387bbe,
            0 0 9px #387bbe;
        -webkit-box-shadow:
            inset 0 0 3px #387bbe,
            0 0 9px #387bbe;
    }

    input[type="number"] {
        color: #8a8383;
        width: 50px;
        height: 30px;
        font-size: 20px;
        border: none;
    }

    input[type="number"]::-webkit-inner-spin-button,
    input[type="number"]::-webkit-outer-spin-button {
        opacity: 1;
    }

    input[type="range"] {
        -webkit-appearance: none;
        appearance: none;
        width: 100%;
        position: absolute;
        background-color: #c6c6c6;
        pointer-events: none;
    }

    .to-slider {
        background: ${({ from, to, sliderColor, rangeColor, max, min }) => {
            const rangeDistance = max - min;
            const fromPosition = from - min;
            const toPosition = to - min;
            return `linear-gradient(
                    to right,
                    ${sliderColor} 0%,
                    ${sliderColor} ${(fromPosition / rangeDistance) * 100}%,
                    ${rangeColor} ${(fromPosition / rangeDistance) * 100}%,
                    ${rangeColor} ${(toPosition / rangeDistance) * 100}%,
                    ${sliderColor} ${(toPosition / rangeDistance) * 100}%,
                    ${sliderColor} 100%
                )`;
        }};
        &:disabled {
            background: #c6c6c6;
        }
    }
`;

function classNames(...classes: string[]) {
    return classes.join(" ");
}

function TimeHorizonSelectionComponentSelector({
    isSelected,
    onChange,
}: {
    isSelected: boolean;
    onChange: () => void;
}) {
    return (
        <Switch.Group
            as="div"
            className="flex items-center justify-start gap-2"
        >
            <span className="flex flex-col">
                <Switch.Label as="span" className="text-sm font-medium" passive>
                    Choose a range of time horizons
                </Switch.Label>
            </span>
            <Switch
                checked={isSelected}
                onChange={onChange}
                className={classNames(
                    isSelected ? "bg-indigo-600" : "bg-gray-200",
                    `relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2
                    border-transparent transition-colors duration-200 ease-in-out focus:outline-none
                    focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2`,
                )}
            >
                <span
                    aria-hidden="true"
                    className={classNames(
                        isSelected ? "translate-x-5" : "translate-x-0",
                        `pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow ring-0
                        transition duration-200 ease-in-out transform`,
                    )}
                />
            </Switch>
            <span className="flex flex-col">
                <Switch.Label
                    as="span"
                    className="text-sm font-medium "
                    passive
                >
                    Choose from a list of time horizons
                </Switch.Label>
            </span>
        </Switch.Group>
    );
}

function TimeHorizonSliderWidget({
    timeHorizons,
    setTimeHorizons,
    selectedTimeHorizons,
    isSingleSelect,
    isDisabled = false,
}: Props) {
    const range = [
        Math.min(...timeHorizons.map((th) => th.time_index)),
        Math.max(...timeHorizons.map((th) => th.time_index)),
    ];

    const [bounds, setBounds] = useState({
        lowerBound: 0,
        upperBound: range[1],
    });

    useEffect(() => {
        const { lowerBound, upperBound } = bounds;

        const ids = timeHorizons
            .filter((th) => {
                return (
                    th.time_index >= lowerBound && th.time_index <= upperBound
                );
            })
            .map((th) => th.id);
        setTimeHorizons(ids);
    }, [bounds]);

    useEffect(() => {
        if (selectedTimeHorizons?.length) {
            const lowerBound = Math.min(
                ...selectedTimeHorizons.map((th) => th.time_index),
            );
            const upperBound = Math.max(
                ...selectedTimeHorizons.map((th) => th.time_index),
            );
            setBounds({ lowerBound, upperBound });
        }
    }, []);

    const setUpperTimeHorizon = useCallback(
        (e: ChangeEvent) => {
            const index = parseInt((e.target as HTMLInputElement).value);
            if (isSingleSelect) {
                setBounds((bounds) => ({
                    ...bounds,
                    lowerBound: index,
                    upperBound: index,
                }));
            } else {
                if (index >= bounds.lowerBound) {
                    setBounds((bounds) => ({
                        ...bounds,
                        upperBound: index,
                    }));
                }
            }
        },
        [bounds.lowerBound, isSingleSelect],
    );

    const setLowerTimeHorizon = useCallback(
        (e: ChangeEvent) => {

            const index = parseInt((e.target as HTMLInputElement).value);
            if (isSingleSelect) {
                setBounds((bounds) => ({
                    ...bounds,
                    lowerBound: index,
                    upperBound: index,
                }));
            } else if (index <= bounds.upperBound) {
                setBounds((bounds) => ({
                    ...bounds,
                    lowerBound: index,
                }));
            }
        },
        [bounds.upperBound],
    );

    return (
        <div
            className={`relative flex w-full max-w-[40rem] flex-col space-x-2 py-4
            data-[disabled="true"]:pointer-events-none
            data-[disabled="true"]:cursor-not-allowed`}
            data-disabled={isDisabled}
        >
            <div className="form_control_container__time">
                Time Horizon{!isSingleSelect && "s"}
            </div>
            <div className="flex w-full max-w-[40rem] items-center space-x-2 py-4">
                {!isSingleSelect && (
                    <div className="form_control_container__time">Min</div>
                )}
                <div>
                    <input
                        className={`block w-12 rounded-md border border-gray-300 bg-white/10 p-2 text-gray-200
                            focus:text-white focus:outline-offset-0 focus:outline-[#1d4ed8] sm:text-sm`}
                        type="number"
                        id="fromInput"
                        value={bounds.lowerBound}
                        onChange={setLowerTimeHorizon}
                        min={range[0]}
                        max={range[1]}
                    />
                </div>
                <SliderWrapper
                    className="relative w-full"
                    from={isSingleSelect ? 0 : bounds.lowerBound}
                    to={bounds.upperBound}
                    sliderColor={"#fff"}
                    rangeColor={"rgb(29, 78, 216)"}
                    min={range[0]}
                    max={range[1]}
                >
                    <input
                        id={"fromSlider"}
                        type="range"
                        className={`absolute z-1 h-0 w-full ${isSingleSelect ? "to-slider" : "from-slider"}`}
                        value={bounds.lowerBound}
                        onChange={setLowerTimeHorizon}
                        min={range[0]}
                        max={range[1]}
                        disabled={isDisabled}
                    />
                    {!isSingleSelect && (
                        <input
                            id="toSlider"
                            type="range"
                            className={"to-slider absolute h-[5px] w-full"}
                            value={bounds.upperBound}
                            onChange={setUpperTimeHorizon}
                            min={range[0]}
                            max={range[1]}
                            disabled={isDisabled}
                        />
                    )}
                </SliderWrapper>
                {!isSingleSelect && (
                    <>
                        <div>
                            <input
                                className={`block w-12 rounded-md border border-gray-300 bg-white/10 p-2 text-gray-200
                                    focus:text-white focus:outline-offset-0 focus:outline-[#1d4ed8] sm:text-sm`}
                                type="number"
                                id="toInput"
                                value={bounds.upperBound}
                                onChange={setUpperTimeHorizon}
                                min={range[0]}
                                max={range[1]}
                            />
                        </div>{" "}
                        <div className="form_control_container__time">Max</div>
                    </>
                )}
            </div>
        </div>
    );
}
function TimeHorizonListSelector({
    selectedTimeHorizons,
    timeHorizons,
    setTimeHorizons,
}: Props) {
    return (
        <div className="flex max-w-sm flex-col gap-4 rounded-md bg-white/10 p-4 py-4">
            <h5>Select Time Horizons</h5>
            <div className="flex flex-wrap gap-4">
                {timeHorizons.map((timeHorizon) => (
                    <div
                        key={timeHorizon.id}
                        className="flex items-center space-x-2"
                    >
                        <input
                            type="checkbox"
                            className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                            checked={selectedTimeHorizons.some(
                                (th) => th.id === timeHorizon.id,
                            )}
                            onChange={() => {
                                if (
                                    selectedTimeHorizons.some(
                                        (th) => th.id === timeHorizon.id,
                                    )
                                ) {
                                    setTimeHorizons(
                                        selectedTimeHorizons
                                            .filter(
                                                (th) =>
                                                    th.id !== timeHorizon.id,
                                            )
                                            .map((th) => th.id),
                                    );
                                } else {
                                    setTimeHorizons([
                                        ...selectedTimeHorizons.map(
                                            (th) => th.id,
                                        ),
                                        timeHorizon.id,
                                    ]);
                                }
                            }}
                        />
                        <label
                            htmlFor="comments"
                            className="block text-sm font-medium"
                        >
                            {timeHorizon.time_index}
                        </label>
                    </div>
                ))}
            </div>
        </div>
    );
}
export default function TimeHorizonForm(props: Props) {
    const [selectionWidget, setSelectionWidget] = useState<"LIST" | "SLIDER">(
        "SLIDER",
    );

    const toggleSelectionWidget = useCallback(() => {
        setSelectionWidget((selectionWidget) => {
            if (selectionWidget === "SLIDER") {
                return "LIST";
            } else {
                return "SLIDER";
            }
        });
    }, []);

    return (
        <div
            className={`flex flex-col gap-4 data-[disabled="true"]:cursor-not-allowed`}
            data-disabled={props.isDisabled || false}
        >
            <TimeHorizonSelectionComponentSelector
                isSelected={selectionWidget !== "SLIDER"}
                onChange={toggleSelectionWidget}
            />
            {selectionWidget === "SLIDER" ? (
                <TimeHorizonSliderWidget {...props} />
            ) : (
                <TimeHorizonListSelector {...props} />
            )}
        </div>
    );
}
